import './App.css';
import { Auth, Storage } from 'aws-amplify';
import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import Checkbox from '@mui/material/Checkbox'; 
import Modal from 'react-bootstrap/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import party from "party-js";
import './christmas.css';
import { Container, Navbar, NavDropdown } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import logo from './upload_portal_logo.png';
import DarkMode from "./DarkMode";
import { withAuthenticator} from '@aws-amplify/ui-react';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MuiAlert from '@mui/material/Alert';
import Nav from 'react-bootstrap/Nav';
import ChatIcon from '@mui/icons-material/Chat';

function App() {
  const [user, updateUser] = useState({userId: '', groups: []});

  //christmas
  var today = new Date();
  var mm = String(today.getMonth() + 1).padStart(2, '0'); 
  if (mm==String(11) || mm==String(12)){
    document.addEventListener('DOMContentLoaded', function(){
        let v = window.innerHeight / 60 + 2,
            h = window.innerWidth / 60 + 2,
            data = {
                'top': h
            },
            c = null,
            ul = null;
        for (let position in data) {
            c = data[position];
            ul = document.createElement('ul');
            ul.className = 'christmas-lights';
            ul.dataset.position = position;
            for (let i = 0; i <= c; i++) {
                ul.append(document.createElement('li'));
            }
            document.body.append(ul);
        }
    });
  }
  //authentication
  const parseAndUpdateUser = (cognitoUserData) => {
    const idToken = cognitoUserData.getSignInUserSession().getIdToken()
    const userId = idToken.payload['identities'][0].userId
    const groups = idToken.payload['custom:ldap'].split(',')
    groups.push(idToken.payload['custom:posix']);
    console.log('user id', userId);
    console.log('user groups', groups);
    return updateUser({userId: userId, groups: groups});
  }
  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
            .then(cognitoUserData => {
              updateUser(cognitoUserData)
            })
            .catch(() => {
                console.log('No signed in user. sign in...')
                Auth.federatedSignIn({customProvider: 'AmazonFederate'})
            });
  }, []);
  
  console.log(user)
  const username = String(user['username']).split('_')[1]
  console.log('current user: ', username)

  //file upload
  const [value,setValue]=useState('');
  const [uploadResponse, setUploadResponse] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState('');
  const [progress, setProgress] = useState('');
  const [fileName, setFileName]=useState("No file chosen");

  const [file, setFile]=useState({});
  
  async function onFileChange(e) {
    setFileName(e.target.files[0].name);
    setFile(e.target.files[0]);
  }
  // function isEmpty(obj) {
  //   for(var key in obj) {
  //       if(obj.hasOwnProperty(key))
  //           return false;
  //   }
  //   return true;
  // }

async function onFileUpload(e) {
  if (file.name === undefined) {
    setUploadResponse('Please select a file to upload');
    setFileName("No file chosen"); // Empty the file selected area
  } else {
    if (value === 'translator') {
      // Check for .docx file when in the translator folder
      if (['docx'].includes(file.name.split('.').pop())) {
        try {
          const info = await Storage.put(value + "/" + username + "_" + file.name.replace(/\s/g, '_'), file, {
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded / 1000} KB / ${progress.total / 1000} KB`);
              setProgress(`Uploaded: ${progress.loaded / 1000} KB / ${progress.total / 1000} KB`);
            }
          });
          setUploadResponse('');
          setUploadSuccess('File received. Will translate the file and send an email with the translated document! It may take over 10 minutes if the file is large.');
          setFileName("No file chosen"); // Empty the file selected area
        } catch (error) {
          console.log('Error uploading file: ', error);
          setUploadResponse('Error uploading file: ', error);
          setFileName("No file chosen");
        }
      } else {
        console.log('Not a .docx file');
        setUploadResponse('Please choose files in the following format: .docx');
        setFileName("No file chosen");
      }
    } else if (value === 'ads_penetration') {
      // Allow Excel files when in the ads_penetration folder
      if (['xls', 'xlsx'].includes(file.name.split('.').pop())) {
        try {
          const info = await Storage.put(value + "/" + username + "_" + file.name.replace(/\s/g, '_'), file, {
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded / 1000} KB / ${progress.total / 1000} KB`);
              setProgress(`Uploaded: ${progress.loaded / 1000} KB / ${progress.total / 1000} KB`);
            }
          });
          setUploadResponse('');
          setUploadSuccess('Excel file received. We will process it and send an email with the status!');
          setFileName("No file chosen"); // Empty the file selected area
        } catch (error) {
          console.log('Error uploading file: ', error);
          setUploadResponse('Error uploading file: ', error);
          setFileName("No file chosen");
        }
      } else {
        console.log('Not an Excel file');
        setUploadResponse('Please choose an Excel file with the following extensions: .xls, .xlsx');
        setFileName("No file chosen");
      }
    } else {
      // Default case for CSV uploads in other folders
      if (file.name.split('.').pop() === 'csv') {
        if (value !== '') {
          if (file.name.split('_')[0] === username) { // Check if username is included in file name
            if (filenamelist.includes(file.name)) {
              handleShow(); // Show modal if there is already a file in the bucket
            } else {
              try {
                const info = await Storage.put(value + "/" + file.name.replace(/\s/g, '_'), file, {
                  progressCallback(progress) {
                    console.log(`Uploaded: ${progress.loaded / 1000} KB / ${progress.total / 1000} KB`);
                    setProgress(`Uploaded: ${progress.loaded / 1000} KB / ${progress.total / 1000} KB`);
                  },
                  contentType: 'text/csv' // Content type is optional
                });
                setUploadResponse('');
                setUploadSuccess('CSV file received. We will process it and send an email with the status!');
                setFileName("No file chosen"); // Empty the file selected area
              } catch (error) {
                console.log('Error uploading file: ', error);
                setUploadResponse('Error uploading file: ', error);
                setFileName("No file chosen");
              }
            }
          } else {
            const filename = file.name;
            if (filenamelist.includes(username + "_" + file.name) || filenamelist.includes(username + "_" + file.name.replace(/\s/g, '_'))) {
              handleShow(); // Show modal if there is already a file in the bucket
            } else {
              try {
                const info = await Storage.put(value + "/" + username + "_" + file.name.replace(/\s/g, '_'), file, {
                  progressCallback(progress) {
                    console.log(`Uploaded: ${progress.loaded / 1000} KB / ${progress.total / 1000} KB`);
                    setProgress(`Uploaded: ${progress.loaded / 1000} KB / ${progress.total / 1000} KB`);
                  },
                  contentType: 'text/csv' // Content type is optional
                });
                setUploadResponse('');
                setUploadSuccess('CSV file received with your username attached: ' + username + '_' + filename + '. We will process it and send an email with the status!');
                setFileName("No file chosen"); // Empty the file selected area
              } catch (error) {
                console.log('Error uploading file: ', error);
                setUploadResponse('Error uploading file: ', error);
                setFileName("No file chosen");
              }
            }
          }
        } else {
          setUploadResponse('Please choose a folder!');
          setFileName("No file chosen");
        }
      } else {
        console.log('Not a CSV file');
        setUploadResponse('Please choose a CSV file!');
        setFileName("No file chosen");
      }
    }
    Storagelist(value); // Refresh file list area
  }
}

  async function overwriteFile(e) {
    if (file.name.split('_')[0] === username){
      try {
        handleClose()
        const info1 = await Storage.put(value+"/"+file.name.replace(/\s/g, '_'), file, {
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded/1000} KB / ${progress.total/1000} KB`);
            setProgress(`Uploaded: ${progress.loaded/1000} KB / ${progress.total/1000} KB`);
          },
          contentType: 'txt/csv' // contentType is optional
        })
        setUploadResponse('');
        setUploadSuccess('File received. Will check the file and send an email with the status of upload!');
      } catch (error) {
        console.log('Error uploading file: ', error);
        setUploadResponse('Error uploading file: ', error);
      }
      setFileName("No file chosen") //empty the file name area
      Storagelist(value); //refresh file list area
      setIsChecked(!isChecked); //uncheck my uploads checkbox
    }else {
      const filename = file.name
      try {
        handleClose()
        const info1 = await Storage.put(value+"/"+username+"_"+file.name.replace(/\s/g, '_'), file, {
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded/1000} KB / ${progress.total/1000} KB`);
            setProgress(`Uploaded: ${progress.loaded/1000} KB / ${progress.total/1000} KB`);
          },
          contentType: 'txt/csv' // contentType is optional
        })
        setUploadResponse('');
        setUploadSuccess('File received with your username attached to the filename: '+username+'_'+filename+'Will check the file and send an email with the status of upload!');
      } catch (error) {
        console.log('Error uploading file: ', error);
        setUploadResponse('Error uploading file: ', error);
      }
      setFileName("No file chosen") //empty the file name area
      Storagelist(value); //refresh file list area
    }
  }

  //File list
  var moment = require("moment");
  const [fileslist, getFiles] = useState([]);
  let filenamelist = [];
  var checks = new Object();
  console.log(fileslist);
  const num_files = fileslist.length
  fileslist.forEach((files) => {
    if(files.key.slice(-3)=='csv'){
      filenamelist.push(files.key.split('/')[2])
    }
  })

  function Storagelist(e) {
    setValue(e)
    if (e.includes('cleaned')){
      Storage.list(e+"/") // for listing ALL files without prefix, pass '' instead
      .then(result => getFiles(result))
      .catch(err => console.log(err))
    }
    else{
      Storage.list("cleaned/"+e+"/") // for listing ALL files without prefix, pass '' instead
      .then(result => getFiles(result))
      .catch(err => console.log(err))
    }
  }
  //check box status management
  const [isChecked, setIsChecked] = useState(false);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
    if (isChecked==false){
      Storage.list("cleaned/"+value+"/"+username) // for listing ALL files without prefix, pass '' instead
        .then(result => getFiles(result))
        .catch(err => console.log(err))
    } else{
      Storagelist(value)
    }
  };
  
  // //File Checkbox
  // fileslist.forEach((files, index) => {
  //   checks['check'+index] = false;
  // });
    
  // const [fileArray, setFileArray] = useState([]);

  // function handleCheckChange(index, e, file) {
  //   checks['check'+index] = !checks['check'+index];
  //   if(checks['check'+index]==true){
  //     fileArray.push(file.key)
  //   } else if(checks['check'+index]==false && fileArray.length>0){
  //     fileArray.pop(file.key)
  //   }
  //   console.log(fileArray)
  //   console.log(checks)
  // }
  // //File download/delete
  
  //File upload Modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false); 
    setFileName("No file chosen")}
  const handleShow = () => setShow(true);

  //File delete Modal
  const [Deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => {
    setDeleteShow(false);}
  const handleDeleteShow = () => {
    setDeleteShow(true);
  }

  // async function onFileDownload(e) {
  //   if (fileArray.length>1){
  //     setShow(true);
  //   } else {
  //     const signedURL = await Storage.get(fileArray, { expires: 60 });
  //     const link = document.createElement('a');
  //     link.href = signedURL;
  //     link.click()
  //   }
  // }

  //File Download
  async function onFileDownload(e, files){
    const signedURL = await Storage.get(files.key, { expires: 60 });
    const link = document.createElement('a');
    link.href = signedURL;
    link.click()
  }
  //File Delete
  const [deletingfile, setDeletingFile] = useState('')
  const [deletingOriginalfile, setDeletingOriginalFile] = useState('')
  async function onFileDelete(e, files){
    setDeletingFile(files.key);
    setDeletingOriginalFile(files.key.substring(8)) //delete this line in the future so it won't delete original
    console.log(files.key.substring(8)) 
    handleDeleteShow()
  }

  async function deleteFile(){
    await Storage.remove(deletingfile);
    await Storage.remove(deletingOriginalfile);
    Storagelist(value);
    setIsChecked(!isChecked); //uncheck my uploads checkbox
    handleDeleteClose();
  }
  let userPicture = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid='+username;
  console.log(userPicture)

  //search bar
  const [searchInput, setSearchInput] = React.useState("")
  const [selected, setSelected] = useState({});
  const handleSelect = (key, event) => {
    setSelected({ key, value: event.target.value });
  };
  const searchhandleChange = (e) => {
    e.preventDefault();
    search(searchInput);
  };

  const [folder, setFolder] = React.useState('');

  const handleChange = (event) => {
    setFolder(event.target.value);
  };
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      },
    }));
  
  async function search(search_text) {
    let search_result=[];
    if (search_text.length > 0) {
      if (folder === ''){
        console.log("cleaned/")
        await Storage.list("cleaned/") // for listing ALL files without prefix, pass '' instead
        .then(result => result.forEach(searchfiles => search_result.push(searchfiles)))
        .catch(err => console.log(err))
      }
      else if (folder !== undefined){
        console.log("cleaned/"+folder+"/")
        await Storage.list("cleaned/"+folder+"/") // for listing ALL files without prefix, pass '' instead
        .then(result => result.forEach(searchfiles => search_result.push(searchfiles)))
        .catch(err => console.log(err))
      }
    };
    let filtered_search = search_result.filter(str => str.key.toLowerCase().includes(search_text.toLowerCase()));
    getFiles(filtered_search)
    setValue("your search")
  }

  //list all my uploads
  async function listAllMine(search_text) {
    let search_result=[];
    await Storage.list("cleaned/") // for listing ALL files without prefix, pass '' instead
    .then(result => result.forEach(searchfiles => search_result.push(searchfiles)))
    .catch(err => console.log(err))
    console.log(search_result)
    let filtered_search = search_result.filter(str => str.key.split('/')[2].split('_')[0].includes(username));
    console.log(filtered_search)
    getFiles(filtered_search)
    setValue("All files uploaded by "+username)
  }

  //user_picture dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userPictureClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const userPictureClose = () => {
    setAnchorEl(null);
  };

  //FAB
  const [fabAnchor, setfabAnchor] = React.useState(null);
  const fabOpen = Boolean(fabAnchor);
  const userFabClick = (event) => {
    setfabAnchor(event.currentTarget);
  };
  const userFabClose = () => {
    setfabAnchor(null);
  };
  const theme = createTheme({
    palette: {
      submit: {
        main: '#64748B',
        contrastText: '#ff6666',
      },
      upload: {
        main: '#008080',
        contrastText: '#ffe4e1',
        dark: '#ff6666'
      }
    },
  });
  
  //Send message function
  const fabStyle = {
    position: 'fixed',
    bottom: '15px',
    right: '15px'
  };
  const [messageInput, setMessageInput] = React.useState("");
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("success")
  var jsonData = {
    'from': username,
    "message": messageInput
  }
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleSnackClick = () => {
    if(snackMessage.length===0){
      setSnackSeverity("error")
    }
    else{
      setSnackSeverity("success")
    }
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };
  const snackAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  //Dynamic file listing
  function renderFile(files, index) {
    return (
      <tr key={index}>
        <td style={{width: "5%", textAlign: "center"}}><IconButton color='primary' onClick={(e) => onFileDownload(e, files)}><DownloadRoundedIcon /></IconButton></td>
        <td style={{width: "5%", textAlign: "center"}}><IconButton onClick={(e) => onFileDelete(e, files)}><ThemeProvider theme={theme}><DeleteRoundedIcon color='upload' /></ThemeProvider></IconButton></td>
        <td style={{width: "22.5%", textAlign: "center"}}>{files.key.split('/')[1]}</td>
        <td style={{width: "22.5%", textAlign: "center"}}>{files.key.split('/')[2]}</td>
        <td style={{width: "22.5%", textAlign: "center"}}>{moment(files.lastModified).format('DD-MM-YYYY')}</td>
        <td style={{width: "22.5%", textAlign: "center"}}>{files.size/1000} KB</td>
      </tr>
    )
  }
  if (username) {
    // User is authenticated, token are valids.
    return (
      <div className="awsui">
        <Navbar variant="dark" fixed="top" className="nav-color">
          <Container fluid>
            <Navbar.Brand href="https://www.uploader.softlines.amazon.dev">
              <img alt="" src={logo} width="180" height="40" className="d-inline-block align-items-center"/>{' '}
            </Navbar.Brand> 
            {/* <Navbar.Toggle aria-controls="navbarScroll" className="bg-info h-25 d-flex align-items-center justify-content-center"/> */}
            <Navbar.Collapse className="justify-content-end">
              <Nav className="me-auto">
                <Nav.Link href="https://sli.translator.amazon.dev">Translator</Nav.Link>
              </Nav>
              <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                onSubmit={searchhandleChange}
              >
                <FormControl variant="standard" sx={{width:'auto', minWidth: 130}}>
                  <InputLabel id="demo-simple-select-label">Folder</InputLabel>
                  <Select
                    displayEmpty
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={folder}
                    label="folder"
                    onChange={handleChange}
                    input={<BootstrapInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Folder</em>;
                      }


                      return selected;
                    }}
                  >
                    <MenuItem value=''>All folder</MenuItem>
                    <MenuItem value='ads_mapping'>ads_mapping</MenuItem>
                    <MenuItem value='ads_penetration'>ads_penetration</MenuItem>
                    <MenuItem value='asin_model_mapping'>asin_model_mapping</MenuItem>
                    <MenuItem value='avn-companies'>avn-companies</MenuItem>
                    <MenuItem value='avs_asin_list'>avs_asin_list</MenuItem>
                    <MenuItem value='backups'>backups</MenuItem>
                    <MenuItem value='bxgy-add-deleteV2'>bxgy-add-deleteV2</MenuItem>
                    <MenuItem value='bxgy-thresholds'>bxgy-thresholds</MenuItem>
                    <MenuItem value='cml_asin_list'>cml_asin_list</MenuItem>
                    <MenuItem value='cml_criteria'>cml_criteria</MenuItem>
                    <MenuItem value='counterfeit'>counterfeit</MenuItem>
                    <MenuItem value='deals-calendar'>deals-calendar</MenuItem>
                    <MenuItem value='hardlines_monthly_forecast'>hardlines_monthly_forecast</MenuItem>
                    <MenuItem value='jbp-uploads'>jbp-uploads</MenuItem>
                    <MenuItem value='jbp-uploads-new'>jbp-uploads-new</MenuItem>
                    <MenuItem value='jbp-pre-planning'>jbp-pre-planning</MenuItem>
                    <MenuItem value='jp-softlines-op2'>jp-softlines-op2</MenuItem>
                    <MenuItem value='marketing_project'>marketing_project</MenuItem>
                    <MenuItem value='mlbp-brand-exclusions'>mlbp-brand-exclusions</MenuItem>
                    <MenuItem value='nbteamcode'>NBteamcode</MenuItem>
                    <MenuItem value='otb-uploads'>otb-uploads</MenuItem>
                    <MenuItem value="scot-override">scot-override</MenuItem>
                    <MenuItem value="shooting_exclusion_list">shooting_exclusion_list</MenuItem>
                    <MenuItem value="size-validations">size-validations</MenuItem>
                    <MenuItem value="spark_deal_sim_rbs_prod">spark_deal_sim_rbs_prod</MenuItem>
                    <MenuItem value="spark_dealV2">spark_dealV2</MenuItem>
                    <MenuItem value="spark_deal_test">spark_deal_test</MenuItem>
                    <MenuItem value="spark_deal_threshold">spark_deal_threshold</MenuItem>
                    <MenuItem value="spark_deal_whitelist">spark_deal_whitelist</MenuItem>
                    <MenuItem value="vssc_excluding_vendor">vssc_excluding_vendor</MenuItem>
                    <MenuItem value="xDE-Target">xDE Target</MenuItem>
                    <MenuItem value="map-upload">map-upload</MenuItem>
                  </Select>
                </FormControl>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Files"
                  inputProps={{ 'aria-label': 'search files' }}
                  onChange={e => setSearchInput(e.target.value)}
                />
                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Navbar.Text className="d-inline-block align-items-center">&nbsp; <a href='https://phonetool.amazon.com/users/' onClick="location.href=this.href+username;return false;">{username}</a>&nbsp;</Navbar.Text>
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={userPictureClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <Avatar sx={{ width: 42, height: 42 }} src={userPicture}/>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={userPictureClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={listAllMine}>
                    <ListItemIcon>
                      <ListAltRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    All my uploads
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <DarkMode />
                  </MenuItem>
              </Menu>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div>
          <Snackbar
            open={snackOpen}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            action={snackAction}
          >
            <Alert onClose={handleClose} severity={snackSeverity}>
              {snackMessage}
            </Alert>
          </Snackbar>
        </div>
        <h5 style={{textAlign: "center", paddingTop: "100px"}}> Hello <span style={{color: "#ff9900"}} onClick={(e) => party.sparkles(e.target)}>{username}</span> !! </h5>
        <h5 style={{textAlign: "center"}}> Welcome to <span style={{color: "#ff9900"}}>SUP</span> !! </h5>
        <h4 style={{textAlign: "center", paddingTop: "30px"}}>Please select a folder you want to upload your <span style={{color: "#ff9900"}}>CSV file</span> to by pressing <span style={{color: "#ff9900"}}>Choose a Folder</span> button. <br />then choose a file by clicking <span style={{color: "#ff9900"}}>choose file button</span> after that <span style={{color: "#ff9900"}}>press upload</span> to upload a file</h4>
        <h4 style={{textAlign: "center", paddingTop: "20px"}}><span style={{color: "#ff9900"}}>CSVファイル</span>をアップロードしたいフォルダを<span style={{color: "#ff9900"}}>Choose a Folder</span>ボタンを押して選択してください。<br /><span style={{color: "#ff9900"}}>choose file</span>ボタンを押してファイルを選択した後、<span style={{color: "#ff9900"}}>Upload!</span>ボタンを押すとアップロードが始まります。</h4>
        <h5 style={{textAlign: "center", paddingTop: "30px"}}>Once you upload your file, your file name will be automatically changed to: <span style={{color: "#ff9900"}}>{username}_[File name]</span></h5>
        <h5 style={{textAlign: "center"}}>ファイルのアップロード後、ファイル名は自動にこのフォーマットに入れ替わります: <span style={{color: "#ff9900"}}>{username}_[File name]</span></h5>
        <div style={{textAlign: "center", paddingTop: "100px"}}>
        <div style={{display: "inline-block"}}>
        <DropdownButton id="dropdown-basic-button" title="Choose a Folder" onSelect={Storagelist} variant="warning">
          <Dropdown.Item eventKey="ads_mapping">ads_mapping</Dropdown.Item>
          <Dropdown.Item eventKey="ads_penetration">ads_penetration</Dropdown.Item>
          <Dropdown.Item eventKey="asin_model_mapping">asin_model_mapping</Dropdown.Item>
          <Dropdown.Item eventKey="avn-companies">avn-companies</Dropdown.Item>
          <Dropdown.Item eventKey="avs_asin_list">avs_asin_list</Dropdown.Item>
          <Dropdown.Item eventKey="backups">backups</Dropdown.Item>
          <Dropdown.Item eventKey="bxgy-add-deleteV2">bxgy-add-deleteV2</Dropdown.Item> 
          <Dropdown.Item eventKey="bxgy-thresholds">bxgy-thresholds</Dropdown.Item>
          <Dropdown.Item eventKey="cml_asin_list">cml_asin_list</Dropdown.Item>  
          <Dropdown.Item eventKey="cml_criteria">cml_criteria</Dropdown.Item>  
          <Dropdown.Item eventKey="counterfeit">counterfeit</Dropdown.Item>  
          <Dropdown.Item eventKey="deals-calendar">deals-calendar</Dropdown.Item>  
          <Dropdown.Item eventKey="hardlines_monthly_forecast">hardlines_monthly_forecast</Dropdown.Item>         
          <Dropdown.Item eventKey="jbp-uploads">jbp-uploads</Dropdown.Item>  
          <Dropdown.Item eventKey="jbp-uploads-new">jbp-uploads-new</Dropdown.Item> 
          <Dropdown.Item eventKey="jbp-pre-planning">jbp-pre-planning</Dropdown.Item>
          <Dropdown.Item eventKey="jp-softlines-op2">jp-softlines-op2</Dropdown.Item>
          <Dropdown.Item eventKey="marketing_project">marketing_project</Dropdown.Item>
          <Dropdown.Item eventKey="mlbp-brand-exclusions">mlbp-brand-exclusions</Dropdown.Item>
          <Dropdown.Item eventKey="nbteamcode">NBteamcode</Dropdown.Item>
          <Dropdown.Item eventKey="otb-uploads">otb-uploads</Dropdown.Item>
          <Dropdown.Item eventKey="scot-override">scot-override</Dropdown.Item>
          <Dropdown.Item eventKey="shooting_exclusion_list">shooting_exclusion_list</Dropdown.Item>
          <Dropdown.Item eventKey="size-validations">size-validations</Dropdown.Item>
          <Dropdown.Item eventKey="spark_deal_sim_rbs_prod">spark_deal_sim_rbs_prod</Dropdown.Item>
          <Dropdown.Item eventKey="spark_dealV2">spark_dealV2</Dropdown.Item>
          <Dropdown.Item eventKey="spark_deal_test">spark_deal_test</Dropdown.Item>          
          <Dropdown.Item eventKey="spark_deal_threshold">spark_deal_threshold</Dropdown.Item>
          <Dropdown.Item eventKey="spark_deal_whitelist">spark_deal_whitelist</Dropdown.Item>
          <Dropdown.Item eventKey="vssc_excluding_vendor">vssc_excluding_vendor</Dropdown.Item>
          <Dropdown.Item eventKey="xDE-Target">xDE Target</Dropdown.Item>
          <Dropdown.Item eventKey="map-upload">map-upload</Dropdown.Item>
        </DropdownButton>
        </div>
        <h4 style={{display: "inline-block", paddingLeft: "20px"}}>You selected <span style={{color: "#ff9900"}}>{value}</span></h4>
        </div>
        
        
        <div className='uploadingfile' style={{display:"flex", alignItems:'center', justifyContent:'center', textAlign: "center", paddingTop: "30px"}}>
          <input id="file-upload" type="file" hidden accept="txt/csv" onChange={(e) => onFileChange(e)}/>
          <label htmlFor="file-upload">
            <ThemeProvider theme={theme}>
              <Button component="span" variant="outlined" startIcon={<AttachFileIcon />} style={{textTransform: 'none'}}> Choose File </Button>
            </ThemeProvider>
          </label>
          <p style={{alignItems:"center"}}>&nbsp; &nbsp; &nbsp;{fileName}&nbsp; &nbsp; &nbsp;</p>
          <ThemeProvider theme={theme}>
            <Button variant="outlined" startIcon={<CloudUploadIcon />} color='upload' onClick={(e) => onFileUpload(e)} style={{textTransform: 'none'}}> Upload </Button>
          </ThemeProvider>
        </div>
        <h5 style={{textAlign: "center", paddingTop: "20px"}}>{progress}</h5>
        <h5 style={{textAlign: "center", paddingTop: "20px", color:"red", whiteSpace: "pre"}}>{uploadResponse}</h5>
        <h5 style={{textAlign: "center", paddingTop: "20px", color:"green", whiteSpace: "pre"}}>{uploadSuccess}</h5>
        <a id="folderButton"></a>
        <div style={{paddingTop: "20px"}}>
          <h5 style={{marginLeft: "25%", display: "inline-block"}}><span style={{color: "#ff9900"}}>{num_files}</span> Files currently in <span style={{color: "#ff9900"}}>{value}</span> folder:</h5>
          <FormGroup style={{marginLeft: "25%", display: "inline-block"}}>
            <FormControlLabel control={<Checkbox  checked={isChecked}  onChange={handleOnChange}  inputProps={{ 'aria-label': 'controlled' }} label="My uploads"/>} label="My Uploads" />
          </FormGroup>
        </div>
        <div style={{paddingTop: "20px", paddingBottom: "100px"}}>
          <table style={{marginLeft: "Auto", marginRight: "Auto", borderCollapse: "collapse", whiteSpace: "nowrap", width: "30%"}}>
            <tbody>
              <tr style={{borderBottom: "5px solid grey"}}>
                <td style={{width: "10%", textAlign: "center", color: "#ff9900"}} colSpan="2"><strong>Select</strong></td>
                <td style={{width: "22.5%", textAlign: "center", color: "#ff9900"}}><strong>Folder</strong></td>
                <td style={{width: "22.5%", textAlign: "center", color: "#ff9900"}}><strong>File Name</strong></td>
                <td style={{width: "22.5%", textAlign: "center", color: "#ff9900"}}><strong>Last Modified</strong></td>
                <td style={{width: "22.5%", textAlign: "center", color: "#ff9900"}}><strong>File Size</strong></td>
              </tr>
              {fileslist.map(renderFile)}
            </tbody>
          </table>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>There is a file with a same name. Do you want to overwrite?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="warning" onClick={overwriteFile}>
              Overwrite
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={Deleteshow} onHide={handleDeleteClose}>
          <Modal.Header closeButton>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this file?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteClose}>
              Close
            </Button>
            <Button variant="danger" onClick={deleteFile}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="awsui">
        <p className="awsui-util-t-c">
           Loading... <br />
        </p>
      </div>
    );
  }
} 

export default App;      
